import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { emptySession, roleMappings } from '../../utils/objects'
import { Amplify } from '../../utils/amplify'
import { capitalizeFirstLetter } from '../../utils/functions'
import { getRequirementTypesList } from '../../utils/userQueries'
const amplify = new Amplify()

export const removeData = ({ navigate, dataDispatch, handleShowInactiveSessionModal }) => {
  if (handleShowInactiveSessionModal) handleShowInactiveSessionModal()

  const clearSession = () => {
    const action = {
      type: dataTypes.logout,
      payload: emptySession
    }
    amplify.cleanToken()
    amplify.signOut()
    dataDispatch(action)
    sessionStorage.clear()
  }

  setTimeout(() => {
    clearSession()
    navigate('/')
  }, 500)
}

export const changePlan = async (currentPlanInfo, dataDispatch, navigate, planToBeUsed, setDropdownIsOpened) => {
  const {
    employeeCredentialId,
    employeeCredentialEmail,
    employeeId,
    rolId,
    roleName,
    idToken
  } = currentPlanInfo

  setDropdownIsOpened((prev) => !prev)

  const type = planToBeUsed === 'OPERATIVO'
    ? dataTypes.operativeLogin
    : planToBeUsed === 'ADMINISTRATIVO'
      ? dataTypes.administrativeLogin
      : dataTypes.lawyerLogin

  // action1 contiene los datos básicos del usuario administrativo que desea cambiar de perfil
  const action1 = {
    type,
    payload: {
      loginDataFetched: {
        findEmployeeBody: {
          employeeCredentialId,
          employeeCredentialEmail,
          employeeId: {
            employeeId,
            roleId: {
              rolId,
              roleName
            }
          }
        },
        idToken
      },
      roleName: capitalizeFirstLetter(planToBeUsed),
      logged: true
    }
  }

  // action2 vacía el contexto antes de realizar el cambio de perfil
  const action2 = {
    type: dataTypes.logout,
    payload: emptySession
  }
  dataDispatch(action2)
  sessionStorage.clear()

  dataDispatch(action1)

  const rolePath = roleMappings[planToBeUsed]

  const { body: reqList } = await getRequirementTypesList(idToken)
  const actionReqList = {
    type: dataTypes.getRequirementList,
    payload: {
      requirementList: reqList
    }
  }
  dataDispatch(actionReqList)
  navigate(`../${employeeId}/${rolePath}`)
}
