import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { capitalizeFirstLetter, passwordEncrypt, saveUserData } from '../../utils/functions'
import { roleMappings } from '../../utils/objects'
import { loginQuery } from '../../utils/queries'
import { getRequirementTypesList } from '../../utils/userQueries'
import {
  activateRequestButton,
  handleRequestButton,
  validateEmailFormat,
  validatePassFormat
} from '../../utils/validateFunctions'

// --------------------------------------------------------------------------------------------
// Login methods
export const login = async (
  e,
  email,
  password,
  dataDispatch,
  handleShow,
  setModalMessage,
  isRequesting,
  setIsRequesting,
  navigate,
  emailInputRef,
  passwordInputRef,
  incorrectInputFormat,
  setIncorrectInputFormat,
  remindMe,
  logInString
) => {
  e.preventDefault()
  if (remindMe) {
    saveUserData(email, password)
  }
  if (
    isRequesting ||
    onNotValidEmailBlur(logInString, email, incorrectInputFormat, setIncorrectInputFormat) ||
    onNotValidPasswordBlur(logInString, password, incorrectInputFormat, setIncorrectInputFormat)
  ) return

  emailInputRef.current.blur()
  passwordInputRef.current.blur()

  const passwordEncrypted = passwordEncrypt(password)
  const timeOutId = handleRequestButton(setIsRequesting)
  const loginQueryResponse = await loginQuery(email, passwordEncrypted)

  if (!loginQueryResponse.response) {
    const errorMessage = await handleLoginResponse(logInString, loginQueryResponse)
    handleLoginError(
      errorMessage,
      setModalMessage,
      handleShow,
      setIsRequesting,
      timeOutId
    )
  } else {
    handleLoginSuccess(
      loginQueryResponse,
      dataDispatch,
      navigate,
      logInString
    )
  }
}

const handleLoginResponse = async (
  logInString,
  loginQueryResponse,
  roleId,
  roleName
) => {
  if (!loginQueryResponse.response) {
    const errorCode = loginQueryResponse?.data?.code
    if (errorCode?.includes('USER_NOT_FOUND')) {
      return logInString.userNotFound
    } else if (errorCode?.includes('USER_NOT_ENABLE_TRY_IN_ONE_MINUTE')) {
      return logInString.maxAttemptsReached
    } else if (errorCode?.includes('WRONG_PASSWORD')) {
      return logInString.wrongCredentials
    } else if (errorCode?.includes('EMPLOYEE_NOT_ENABLE_TRY_IN_ONE_MINUTE')) {
      return logInString.maxAttempts
    } else if (errorCode?.includes('EMPLOYEE_NOT_FOUND')) {
      return logInString.userNotFound
    } else {
      return logInString.unknownError
    }
  } else {
    if (!roleId) {
      console.error(logInString.rolIdUnauthorized)
      return logInString.rolIdUnauthorized
    }

    switch (roleId) {
      case 6:
        return {
          type: dataTypes.administrativeLogin,
          payload: {
            loginDataFetched: loginQueryResponse?.body,
            roleName: capitalizeFirstLetter(roleName),
            logged: true
          }
        }
      case 5:
        return {
          type: dataTypes.operativeLogin,
          payload: {
            loginDataFetched: loginQueryResponse?.body,
            roleName: capitalizeFirstLetter(roleName),
            logged: true
          }
        }
      case 4:
        return {
          type: dataTypes.lawyerLogin,
          payload: {
            loginDataFetched: loginQueryResponse?.body,
            roleName: capitalizeFirstLetter(roleName),
            logged: true
          }
        }
      default:
        return null
    }
  }
}

const handleLoginError = (
  errorMessage,
  setModalMessage,
  handleShow,
  setIsRequesting,
  timeOutId
) => {
  setModalMessage(errorMessage)
  handleShow()
  activateRequestButton(setIsRequesting, timeOutId)
}

const handleLoginSuccess = async (
  loginQueryResponse,
  dataDispatch,
  navigate,
  logInString
) => {
  const { body } = loginQueryResponse
  const { findEmployeeBody, idToken } = body
  const { employeeId } = findEmployeeBody
  const { roleId } = employeeId
  const { roleName } = roleId
  const { employeeId: employeeIdValue } = employeeId

  const action = await handleLoginResponse(logInString, loginQueryResponse, roleId.rolId, roleName)
  dataDispatch(action)

  const rolePath = roleMappings[roleName]

  const { body: reqList } = await getRequirementTypesList(idToken)
  const actionReqList = {
    type: dataTypes.getRequirementList,
    payload: {
      requirementList: reqList
    }
  }
  dataDispatch(actionReqList)

  navigate(`Cuenta/${employeeIdValue}/${rolePath}`)
}

// --------------------------------------------------------------------------------------------
// Input methods

export const onEmailChange = (e, loginValues, setLoginValues) => {
  const email = e.target.value
  setLoginValues({ ...loginValues, email })
}

export const onPasswordChange = (e, loginValues, setLoginValues) => {
  const password = e.target.value
  setLoginValues({ ...loginValues, password })
}

export const onNotValidEmailBlur = (logInString, email, incorrectInputFormat, setIncorrectInputFormat) => {
  const isEmailFormatIncorrect = !validateEmailFormat(email)
  const inputFormatClass = isEmailFormatIncorrect ? 'loginIncorrectFormat' : 'loginRightFormat'
  const inputFormatMessage = isEmailFormatIncorrect ? logInString.incorrectEmailFormat : ''

  setIncorrectInputFormat({ ...incorrectInputFormat, inputFormatClass, inputFormatMessage })

  return !!isEmailFormatIncorrect
}

export const onNotValidPasswordBlur = (logInString, password, incorrectInputFormat, setIncorrectInputFormat) => {
  const isPasswordFormatIncorrect = !validatePassFormat(password)
  const inputFormatClass = isPasswordFormatIncorrect ? 'loginIncorrectFormat' : 'loginRightFormat'
  const inputFormatMessage = isPasswordFormatIncorrect ? logInString.incorrectPasswordFormat : ''

  setIncorrectInputFormat({ ...incorrectInputFormat, inputFormatClass, inputFormatMessage })

  return !!isPasswordFormatIncorrect
}

export const handleEyeIconClick = (
  passwordState,
  setPasswordState,
  openEyeIcon,
  closeEyeIcon
) => {
  const isPasswordVisible = !passwordState.isPasswordVisible
  const newInputType = isPasswordVisible ? 'text' : 'password'
  const newEyeIcon = isPasswordVisible ? closeEyeIcon : openEyeIcon

  setPasswordState({
    isPasswordVisible,
    passwordInputType: newInputType,
    eyeIcon: newEyeIcon
  })
}
