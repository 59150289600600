import React, { useContext, useEffect, useState } from 'react'
import { CustomSelect } from '../../../../components/CustomSelect/CustomSelect'
import { getLocationValues } from '../EditPQRSUser/EditPQRSUserFunctions'
import { DataContext } from '../../../../context/DataContext'
import { maxSize, validateCellphoneLength, validateCompanyName, validateEmailFormat, validateNitLength } from '../../../../utils/validateFunctions'
import { onCreateEmailChange, onFormInputChange, onNitChange, onPhoneChange, validateInputOnBlur } from '../../../UsersAdmin/utils/createAndEditUserMethods'
import { cancelEdit, handleSubmit, onFirstCheckboxChange, onSecondCheckboxChange, onThirdCheckboxChange, onUploadLogoClick } from './CreateNewCompanyFunctions'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import userPersonImg from '../../../../assets/images/userPerson.png'
import userCompany from '../../../../assets/images/userCompany.png'
import habilitarIcon from '../../../../assets/icons/icon_bien.svg'
import './CreateNewCompany.css'
import { getCompanyCredentialsExistsValidation, getCompanyPhoneExistsValidation } from '../../../../utils/userQueries'
import { useIncorrectInputFormat } from '../../hooks/useIncorrectInputFormat'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const CreateNewCompany = ({ state }) => {
  const { scriptsSave } = useContext(ScriptContext)
  const { isCompanyUser = false } = state
  const { pathname } = useLocation()

  const navigationPath = pathname.endsWith('CrearEmpresa') ? '..' : '../SolicitudDelUsuario'
  const navigate = useNavigate()
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, citiesFetched, statesFetched } = dataState.operative

  const inputValues = {
    state: null,
    city: null,
    companyNit: null,
    email: null,
    phone: null,
    bussinessName: null,
    comertialName: null,
    file: null,
    categoryCompany: {
      bank: false,
      publicServices: false,
      noChannel: false
    }
  }

  const [createValues, setCreateValues] = useState(inputValues)
  const [validateValues, setValidateValues] = useState(inputValues)
  const [citiesFiltered, setCitiesFiltered] = useState([])
  const [isDisabled, setisDisabled] = useState(true)

  const [showSaveModal, handleSaveModalShow] = useModal()
  const [showCancelModal, handleCancelModalShow, handleCancelModalClose] = useModal()
  const [incorrectInputFormat, setIncorrectInputFormat] = useIncorrectInputFormat()
  const [isComertialName, setIsComertialName] = useState(false)
  const [isBussinessName, setIsBussinessName] = useState(false)
  const [isEmail, setIsEmail] = useState(false)

  useEffect(() => {
    getLocationValues({ citiesFetched, statesFetched, dataDispatch })
  }, [])

  useEffect(() => {
    const { companyNit, phone, ...restOfValues } = createValues
    const values = Object.values(restOfValues)
    const areSomeNull = values.map((value) => value === null || value === '').includes(true)
    if (!areSomeNull) {
      const valuestToValidate = Object.values(validateValues)
      const areWrongFields = valuestToValidate.map(
        (value) => {
          return value === true || value === 'NOT_VALID' || value === 'EMPTY'
        }).includes(true)
      setisDisabled(areWrongFields)
      if (createValues.bussinessName.length < 3) setisDisabled(true)
    } else {
      setisDisabled(true)
    }
  }, [createValues, validateValues])

  useEffect(() => {
    const stateChoosedId = createValues?.state || 1
    const citiesFiltered = citiesFetched.filter(
      (city) =>
        city.stateId.stateId === stateChoosedId
    )
    setCitiesFiltered(citiesFiltered)
  }, [citiesFetched, createValues?.state])

  const handleSelectState = (option) => setCreateValues({ ...createValues, state: option })
  const handleSelectCity = (option) => setCreateValues({ ...createValues, city: option })

  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState('')
  return (
    <section id='CreateNewCompany'>
      <img
        src={!isCompanyUser ? userPersonImg : userCompany}
        className='CreateNewCompanyImg'
        alt={scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.altImageTxt1 : ''}
      />
      <form className='CreateNewCompanyForm' onSubmit={
        (e) => handleSubmit(
          e,
          idToken,
          createValues,
          dataContext,
          handleSaveModalShow,
          setisDisabled,
          setIsComertialName,
          setIncorrectInputFormat,
          incorrectInputFormat,
          setIsBussinessName,
          setIsEmail
        )}>
        <div className='CreateNewCompanyFormGrid'>
          <div className="CreateNewCompanyFormItem">
            <p className="CreateNewCompanyFormItemText">
              {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.comertialName : ''}
            </p>
            <input
              type="text"
              name='comertialName'
              onInput={(e) => maxSize(e, 'compName')}
              onChange={(e) => onFormInputChange(e, createValues, setCreateValues, validateCompanyName, incorrectInputFormat, setIncorrectInputFormat)}
              className='CreateNewCompanyFormItemInput'
              minLength={3}
              value={createValues.comertialName}
              required />
            {isComertialName &&
              <span className={incorrectInputFormat.comertialNameFormat}>{incorrectInputFormat.comertialNameMessage}</span>
            }
          </div>
          <div className="CreateNewCompanyFormItem">
            <p className="CreateNewCompanyFormItemText">
              {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.bussinessName : ''}
            </p>
            <input
              type="text"
              name='bussinessName'
              onInput={(e) => maxSize(e, 'compName')}
              onChange={(e) => onFormInputChange(e, createValues, setCreateValues, validateCompanyName, incorrectInputFormat, setIncorrectInputFormat)}
              className={`CreateNewCompanyFormItemInput ${validateValues.bussinessName ? 'inputIsWrong' : ''}`}
              value={createValues.bussinessName}
              required />
            {validateValues.bussinessName && <p className='labelIsWrong'>*Este nombre ya existe</p>}
            {isBussinessName &&
              <span className={incorrectInputFormat.bussinessNameFormat}>{incorrectInputFormat.bussinessNameMessage}</span>
            }
          </div>
          <div className="CreateNewCompanyFormItem">
            <p className="CreateNewCompanyFormItemText">
              {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.nit : ''}
            </p>
            <input
              type="text"
              name='companyNit'
              value={createValues.companyNit || ''}
              onInput={(e) => maxSize(e, 'nit')}
              onChange={(e) => onNitChange(e, createValues, setCreateValues)}
              onBlur={(e) => validateInputOnBlur(
                e,
                validateValues,
                setValidateValues,
                getCompanyCredentialsExistsValidation,
                { idToken, companyNit: createValues.companyNit, dataContext },
                undefined,
                validateNitLength
              )}
              className={`CreateNewCompanyFormItemInput ${validateValues.companyNit ? 'inputIsWrong' : ''}`}
            />
            {
              validateValues.companyNit === true
                ? <p className='labelIsWrong'>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.wrongNitMessage1 : ''}</p>
                : validateValues.companyNit === 'EMPTY'
                  ? <p className='labelIsWrong'>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.wrongNitMessage2 : ''}</p>
                  : null
            }
          </div>
          <div className="CreateNewCompanyFormItem">
            <p className="CreateNewCompanyFormItemText">
              {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.email : ''}
            </p>
            <input
              type="text"
              name='email'
              onInput={(e) => maxSize(e, 'email')}
              onChange={(e) => onCreateEmailChange(e, createValues, setCreateValues, validateEmailFormat, incorrectInputFormat, setIncorrectInputFormat)}
              onBlur={(e) => validateInputOnBlur(
                e,
                validateValues,
                setValidateValues,
                getCompanyCredentialsExistsValidation,
                { idToken, companyEmail: createValues.email, dataContext },
                undefined,
                validateEmailFormat
              )
              }
              value={createValues.email}
              className={`CreateNewCompanyFormItemInput ${validateValues.email === true ? 'inputIsWrong' : ''}`}
              required />
            {
              validateValues.email === true
                ? <p className='labelIsWrong'>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.wrongEmailMessage1 : ''}</p>
                : validateValues.email === 'NOT_VALID'
                  ? <p className='labelIsWrong'>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.wrongEmailMessage2 : ''}</p>
                  : null
            }
          </div>
          <div className="CreateNewCompanyFormItem">
            <p className="CreateNewCompanyFormItemText">
              {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.phone : ''}
            </p>
            <input
              type="text"
              name='phone'
              value={createValues.phone || ''}
              onInput={(e) => maxSize(e, 'phone')}
              onChange={(e) => onPhoneChange(e, createValues, setCreateValues)}
              onBlur={(e) => validateInputOnBlur(
                e,
                validateValues,
                setValidateValues,
                getCompanyPhoneExistsValidation,
                { idToken, createValues, dataContext },
                undefined,
                validateCellphoneLength
              )}
              className={`CreateNewCompanyFormItemInput ${validateValues.phone ? 'inputIsWrong' : ''}`}
            />
            {
              (validateValues.phone && createValues.companyNit)
                ? validateValues.phone === 'PHONE_NOT_ALLOW'
                  ? <p className='labelIsWrong'>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.wrongPhoneMessage3 : ''}</p>
                  : validateValues.phone === 'NOT_VALID'
                    ? <p className='labelIsWrong'>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.wrongPhoneMessage4 : ''}</p>
                    : <p className='labelIsWrong'>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.wrongPhoneMessage1 : ''}</p>
                : validateValues.phone === 'PHONE_NOT_ALLOW'
                  ? <p className='labelIsWrong'>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.wrongPhoneMessage3 : ''}</p>
                  : validateValues.phone && <p className='labelIsWrong'>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.wrongPhoneMessage2 : ''}</p>
            }
            {isEmail &&
              <span className={incorrectInputFormat.companyEmailFormat}>{incorrectInputFormat.companyEmailMessage}</span>
            }
          </div>
          <div className="CreateNewCompanyFormItem">
            <p className="CreateNewCompanyFormItemText">
              {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.state : ''}
            </p>
            <CustomSelect selector={handleSelectState} defaultValue=' '>
              {
                statesFetched?.map((state) => (
                  <option
                    key={state.stateId}
                    className='CustomSelectOptionsItem'
                    value={state.stateId}
                  >
                    {state.stateName}
                  </option>
                ))
              }
            </CustomSelect>
          </div>
          {/* <div className="CreateNewCompanyFormItem">
            <p className="CreateNewCompanyFormItemText">
              {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.logo : ''}
            </p>
            <input
              type="file"
              accept='.jpg, .jpeg, .png'
              onChange={(e) => onUploadLogoClick(
                e,
                setCreateValues,
                createValues,
                scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.validateExtension : '',
                setModalMessage,
                handleShow)}
              id='uploadLogo'
              required />
            <label htmlFor="uploadLogo" className='UploadLogoButton'>
              {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.uploadLogo : ''}
            </label>
            <p>{ }</p>
          </div> */}
          <div className="editCompanyFormItem">
            <p className="editCompanyFormItemText">
              {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.selectOptions : ''}
            </p>
            <p className='editCompanyFormSubitemText'>
              {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.optional : ''}
            </p>
            <div className='editCompanyChkBoxMessageContainer'>
              <input
                className='editCompanyChkBox'
                type='checkbox'
                checked={createValues.categoryCompany.bank}
                name='bankEntity'
                onChange={(e) => onFirstCheckboxChange(e, createValues, setCreateValues)}
              />
              <p className="editCompanyFormItemText2">
                {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.option1 : ''}
              </p>
            </div>
            <div className='editCompanyChkBoxMessageContainer'>
              <input
                className='editCompanyChkBox'
                type='checkbox'
                checked={createValues.categoryCompany.publicServices}
                name='bankEntity'
                onChange={(e) => onSecondCheckboxChange(e, createValues, setCreateValues)}
              />
              <p className="editCompanyFormItemText2">
                {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.option2 : ''}
              </p>
            </div>
            <div className='editCompanyChkBoxMessageContainer'>
              <input
                className='editCompanyChkBox'
                type='checkbox'
                checked={createValues.categoryCompany.noChannel}
                name='bankEntity'
                onChange={(e) => onThirdCheckboxChange(e, createValues, setCreateValues)}
              />
              <p className="editCompanyFormItemText2">
                {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.option3 : ''}
              </p>
            </div>
            {/* <div className='editCompanyChkBoxMessageContainer'>
              <input
                className='editCompanyChkBox'
                type='checkbox'
                checked={false}
                name='bankEntity'
                />
              <p className="editCompanyFormItemText2">
                {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.option4 : ''}
              </p>
            </div> */}
            <p>{ }</p>
          </div>
          <div>
            <div className="CreateNewCompanyFormItem">
              <p className="CreateNewCompanyFormItemText">
                {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.city : ''}
              </p>
              <CustomSelect selector={handleSelectCity} defaultValue={' '}>
                {citiesFiltered?.map((city) => (
                  <option
                    key={city.cityId}
                    value={city.cityId}
                    className='CustomSelectOptionsItem'
                    required
                  >
                    {city.cityName}
                  </option>
                ))}
              </CustomSelect>
            </div>
            <div className="CreateNewCompanyFormItem CreateNewCompanyLogoItem">
              {
                createValues?.file?.name
                  ? (
                    <div className='createCompanyLoadedImageContainer'>
                      <img
                        className='createCompanyLoadedImage'
                        src={habilitarIcon}
                        alt={scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.altImageTxt2 : ''}
                      />
                      <p className='createCompanyLoadedImageText'>{createValues?.file?.name}</p>
                    </div>
                    )
                  : (
                    <></>
                    )
              }
              <input
                type="file"
                accept='.jpg, .jpeg, .png'
                onChange={(e) => onUploadLogoClick(
                  e,
                  setCreateValues,
                  createValues,
                  scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.validateExtension : '',
                  setModalMessage,
                  handleShow)}
                id='uploadLogo'
                required />
              <label htmlFor="uploadLogo" className='UploadLogoButton'>
                {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.uploadLogo : ''}
              </label>
              <p>{ }</p>
            </div>
          </div>
        </div>
        <div className="navFooter">
          <button
            className="navFooterBackButton"
            onClick={(e) =>
              cancelEdit(e, isDisabled, handleCancelModalShow, navigate, state, navigationPath)
            }
          >
            <img
              className="navFooterBackLinkImage"
              src={backIcon}
              alt={scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.altImageTxt3 : ''}
            />
            <span className="navFooterBackLinkText">
              {scriptsSave && scriptsSave.navFooterStrings ? scriptsSave.navFooterStrings.goBack : ''}
            </span>
          </button>
          <div className="navFooterButtonsContainer">
            <button
              className="EditPQRSUserBtn"
              name='cancel'
              onClick={(e) =>
                cancelEdit(e, isDisabled, handleCancelModalShow, navigate, state, navigationPath)
              }
            >
              {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.cancel : ''}
            </button>
            <button
              className="EditPQRSUserBtn"
              type='submit'
              name='submit'
              disabled={isDisabled}
            >
              {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.save : ''}
            </button>
          </div>
        </div>
      </form>
      <Modal isOpen={showSaveModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.saveModalMessage : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-orangeBtn" onClick={() => navigate(navigationPath, { state })}>
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showCancelModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.cancelModalMessage : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-normalBtn" onClick={() => navigate(navigationPath, { state })}>
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.cancel : ''}
          </button>
          <button
            className="Modal-orangeBtn"
            onClick={handleCancelModalClose}
          >
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-orangeBtn" onClick={handleClose}>
            {scriptsSave && scriptsSave.createNewCompanyString ? scriptsSave.createNewCompanyString.ok : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
